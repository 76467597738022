import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useCustomMutation from "@/hooks/queries/useCustomMutation";

import { routes } from "@/routes";
import * as APIS from "@/services/apis";
import { showToast } from "@/features/toast/toastSlice";
import { closeModal } from "@/features/modal/modalSlice";
import { showLoading, hideLoading } from "@/features/loading/loadingSlice";
import usePendingCheck from "@/hooks/usePendingCheck";
import { IAccountInfo } from "./useAccountInfo";
interface IError {
  response?: {
    data?: {
      message: string;
    };
  };
}

const useAccountChange = (
  accountType: "DEPOSIT" | "WITHDRAW",
  refetch: () => void,
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pendingCheck = usePendingCheck();

  const accountChange =
    accountType === "DEPOSIT"
      ? APIS.postDepositFromWithdraw
      : APIS.postWithdrawFromDeposit;

  const pendingCompleteAccountCheck = (
    bankType: string,
    refetch: () => void,
  ) => {
    pendingCheck<IAccountInfo>({
      checkFn: () => APIS.getAccountInfo(bankType),
      successCondition: (data) => data.bankAccountStatus === "COMPLETED",
      processingCondition: (data) => data.bankAccountStatus === "REQUEST",
      successMessage: "계좌가 등록되었습니다.",
      processingMessage: "계좌 등록 중 입니다.",
      failureMessage: "계좌 등록에 실패하였습니다.",
      navigateOnSuccess: () => {
        dispatch(closeModal());
        refetch();
      },
      navigateOnProcessing: () => {
        dispatch(closeModal());
        navigate(routes.menu);
      },
      navigateOnFailure: () => {
        dispatch(closeModal());
      },
    });
  };

  const mutationFn = async (bankType: string): Promise<any> => {
    dispatch(showLoading());
    try {
      const response = await accountChange();
      dispatch(hideLoading());
      if (response.data.success) {
        pendingCompleteAccountCheck(bankType, refetch);
      } else {
        dispatch(
          showToast({ message: response.data.message, icon: "caution" }),
        );
        dispatch(closeModal());
      }
    } catch (error) {
      dispatch(hideLoading());
      const err = error as IError;
      if (err.response && err.response.data && err.response.data.message) {
        dispatch(
          showToast({ message: err.response.data.message, icon: "error" }),
        );
      }
      throw error;
    }
  };

  const mutation = useCustomMutation([routes.myAccountManagement], mutationFn);

  return mutation;
};

export default useAccountChange;
