import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as styles from "../styles.css";
import { RootState } from "@/app/store";
import { numberWithCommas } from "@/utils/utils";
import { showToast } from "@/features/toast/toastSlice";
import { setPurchaseInfo } from "@/features/purchase/purchaseSlice";
import useUserAssets from "../hooks/useUserAssets";
import checkboxOn from "@assets/icons/checkBox_on.png";
import checkboxOff from "@assets/icons/checkBox_off.png";

interface IAssetsProps {
  assetType: string;
  goodsGram: number;
}

const Assets: React.FC<IAssetsProps> = ({ assetType, goodsGram }) => {
  const dispatch = useDispatch();
  const purchaseInfo = useSelector((state: RootState) => state.purchase);
  const [useGram, setUseGram] = useState(purchaseInfo?.useGram || 0);
  const { UserAssets } = useUserAssets();
  const { isUseKrw } = purchaseInfo;

  // 결제 수단이 가상계좌가 되면 현금 사용 필수
  useEffect(() => {
    if (purchaseInfo.depositMethod === "NONE") {
      dispatch(setPurchaseInfo({ isUseKrw: true }));
    }
  }, [purchaseInfo.depositMethod]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newGram = Number(e.target.value);
    setUseGram(newGram);
  };

  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    handleGramChange(Number(e.target.value)); // 입력이 완료되고 필드에서 포커스가 벗어났을 때 중량 계산 로직 실행
  };

  const handleGramChange = (newGram: number) => {
    const userGram =
      (assetType === "GOLD"
        ? UserAssets?.acceptableGold
        : UserAssets?.acceptableSilver) ?? 0;
    const maxAvailableGrams = Math.min(
      newGram,
      userGram,
      goodsGram * purchaseInfo.qty,
    ); // 상품 중량이나 사용자 중량보다 작게
    const unit = assetType === "GOLD" ? 0.005 : 0.5; // 소수점 단위
    const validGram = Math.floor(maxAvailableGrams / unit) * unit;

    // 상품 중량 * 수량과 동일한 중량을 사용하고 있을 경우 0으로 설정
    if (useGram === goodsGram * purchaseInfo.qty) {
      setUseGram(0);
    } else {
      setUseGram(validGram);
    }

    dispatch(
      setPurchaseInfo({
        useGram: useGram === goodsGram * purchaseInfo.qty ? 0 : validGram,
      }),
    );
  };

  const handleCashToggle = () => {
    if (purchaseInfo.depositMethod === "NONE") {
      dispatch(
        showToast({
          message:
            "가상 계좌 입금 및 결제 방식 선택 시 보유한 현금을 사용해서 결제합니다.",
          icon: "info",
        }),
      );
    } else {
      dispatch(setPurchaseInfo({ isUseKrw: !isUseKrw }));
    }
  };

  return (
    <div>
      <div className="shadow88 sub02_04">
        <h3 className="shadow88_tit">보유 자산</h3>
        <div className="box_tit02 mb08">
          <div className="box_stit02">
            <h3>현금</h3>
            <div className={styles.flexRow}>
              <img
                className={styles.checkImg}
                src={isUseKrw ? checkboxOn : checkboxOff}
                alt="checkbox"
                onClick={handleCashToggle}
              />
              <div className={styles.checkboxLabelText}>
                보유한 현금 전액 사용
              </div>
            </div>
          </div>
          <ul className="sub02_04_ul01">
            <li>
              <p>사용 가능</p>
            </li>
            <li className="fc-bl02">
              <span>{numberWithCommas(UserAssets?.acceptableKrw ?? 0)}</span>{" "}
              KRW
            </li>
          </ul>
        </div>
        <div className="box_tit02 mb08">
          <div className="box_stit02">
            <h3>{assetType === "GOLD" ? "금" : "은"}</h3>
            <button
              className={styles.allUseBtn}
              onClick={() =>
                handleGramChange(
                  assetType === "GOLD"
                    ? UserAssets?.acceptableGold ?? 0
                    : UserAssets?.acceptableSilver ?? 0,
                )
              }
            >
              {purchaseInfo.qty * goodsGram === useGram
                ? "사용 안함"
                : "모두 사용"}
            </button>
          </div>
          <ul className="sub02_04_ul01">
            <li>
              <p>사용 가능</p>
            </li>
            <li className="fc-bl02">
              <span>
                {numberWithCommas(
                  assetType === "GOLD"
                    ? UserAssets?.acceptableGold
                    : UserAssets?.acceptableSilver,
                )}
              </span>{" "}
              g
            </li>
          </ul>
          <input
            type="number"
            placeholder="사용할 중량을 입력하세요."
            value={useGram}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
          />
        </div>
        <div className="box_tit02 mb08">
          <div className="box_stit02">
            <h3>포인트</h3>
          </div>
          <ul className="sub02_04_ul01">
            <li>
              <p>사용 가능</p>
            </li>
            <li className="fc-bl02">
              <span>{numberWithCommas(UserAssets?.acceptablePoint ?? 0)}</span>{" "}
              pt
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Assets;
