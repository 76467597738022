import * as style from "../styles.css";
import icoNoti from "@assets/icons/icon_noti_filled_gray.png";
const NoRecords = () => {
  return (
    <div className={style.noRecords.pageWrapper}>
      <img
        alt="notification"
        src={icoNoti}
        className={style.noRecords.ico_noti}
      />
      <div className={style.noRecords.kor}>이용 내역이 없습니다.</div>
    </div>
  );
};

export default NoRecords;
