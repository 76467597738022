import React, { useState, useEffect } from "react";
import moment from "moment";
import iconArrowLeft from "../../../assets/icons/ico_arrow_left_c.png";
import iconArrowRight from "../../../assets/icons/ico_arrow_right_c.png";
import { getShopHours } from "../../../services/apis";
import { calcDate, formatTime } from "../../../utils/utils";

interface ReservationDataProps {
  shopId?: number | null;
  visitDate?: any;
}
interface CalendarProps {
  reservationData: ReservationDataProps;
  setReservationData: React.Dispatch<React.SetStateAction<object>>;
  earliestVisitDate?: string;
}
interface BusinessHour {
  dayWeek: string;
  openHr: string;
  closeHr: string;
  isFullDayOff: boolean;
}
interface HolidaysHour {
  manageDate: string;
  isFullDayOff: boolean;
  openHr: string;
  closeHr: string;
}

const DAYS_OF_WEEK = ["일", "월", "화", "수", "목", "금", "토"];

// TODO
// 요일 한글로 표시 안됨.

const Calendar: React.FC<CalendarProps> = ({
  reservationData,
  setReservationData,
  earliestVisitDate = "",
}) => {
  const [month, setMonth] = useState(new Date());
  const [workingDays, setWorkingDays] = useState<
    { date: string; isFullDayOff: boolean; open: string; close: string }[]
  >([]);
  const { shopId, visitDate } = reservationData;
  const minDate = earliestVisitDate
    ? new Date(earliestVisitDate) > new Date()
      ? new Date(earliestVisitDate)
      : new Date()
    : new Date();
  const maxDate = calcDate("M", 1);
  const holidays = workingDays
    .filter(({ isFullDayOff }) => isFullDayOff)
    .map(({ date }) => date);

  const handleChangeMonth = (month: Date) => {
    setMonth(month);
  };

  const handleChangeDate = (date: any) => {
    const schedule =
      workingDays.find(
        ({ date: workingDate }) => workingDate === formatTime(date),
      ) || {};
    setReservationData({
      ...reservationData,
      ...schedule,
      visitDate: formatTime(date),
    });
  };

  const generate = () => {
    const today = moment(month);
    const startWeek = today.clone().startOf("month").week();
    const endWeek =
      today.clone().endOf("month").week() === 1
        ? 53
        : today.clone().endOf("month").week();
    let calendar = [];
    for (let week = startWeek; week <= endWeek; week++) {
      calendar.push(
        <tr className="date">
          {Array(7)
            .fill(0)
            .map((n, i) => {
              let current = today
                .clone()
                .startOf("year")
                .week(week)
                .startOf("week")
                .add(n + i, "day");

              const dateString = formatTime(current.toDate());

              const outOfRange =
                (minDate && formatTime(minDate) > dateString) ||
                (maxDate && formatTime(maxDate) < dateString);

              const isOtherMonth = current.format("M") !== today.format("M");

              const isChecked =
                visitDate &&
                !isOtherMonth &&
                formatTime(visitDate) === dateString;

              const isDisabled = holidays.includes(dateString) || outOfRange;
              const isToday = current.isSame(new Date(), "day");

              return (
                <td
                  key={i}
                  className={`${isChecked ? "selected" : ""} ${
                    isDisabled ? "disabled" : ""
                  }`}
                  onClick={() => {
                    !isOtherMonth &&
                      !isDisabled &&
                      handleChangeDate(current.toDate());
                  }}
                >
                  <p>{!isOtherMonth && current.format("D")}</p>
                  {isToday && <span className="cal_today">오늘</span>}
                </td>
              );
            })}
        </tr>,
      );
    }
    return calendar;
  };
  // useEffect(() => {
  //   if (
  //     minDate &&
  //     formatTime(minDate, "YYYY-MM") > formatTime(month, "YYYY-MM")
  //   ) {
  //     console.log("chekc");
  //     console.log(minDate, "minDate");
  //     handleChangeMonth(minDate);
  //   }
  // }, [minDate]);

  useEffect(() => {
    if (visitDate) {
      if (
        (minDate && formatTime(visitDate) < formatTime(minDate)) ||
        (maxDate && formatTime(visitDate) > formatTime(maxDate))
      ) {
        handleChangeDate(null);
      }
    }
  }, [minDate, maxDate]);

  useEffect(() => {
    if (visitDate && holidays.includes(formatTime(visitDate))) {
      handleChangeDate(null);
    }
  }, [holidays]);

  useEffect(() => {
    if (shopId && month) {
      getShopHours(shopId, formatTime(month, "YYYY-MM")).then(
        ({ data: { success, message, data } }) => {
          if (success) {
            const { businessHoursList = [], holidaysList = [] } = data;
            const businessList: {
              [key: string]: {
                isFullDayOff: boolean;
                open: string;
                close: string;
              };
            } = {};
            const resList: {
              [key: string]: {
                isFullDayOff: boolean;
                open: string;
                close: string;
              };
            } = {};
            const resList2: {
              date: string;
              isFullDayOff: boolean;
              open: string;
              close: string;
            }[] = [];

            businessHoursList.forEach(
              ({ dayWeek, isFullDayOff, openHr, closeHr }: BusinessHour) => {
                businessList[dayWeek] = {
                  isFullDayOff,
                  open: openHr,
                  close: closeHr,
                };
              },
            );
            const copy = new Date(month);
            copy.setMonth(copy.getMonth() + 1);
            copy.setDate(0);
            const lastDay = copy.getDate();

            for (let i = 1; i <= lastDay; i++) {
              const current = new Date(month);
              current.setDate(i);
              const currentDay = current.getDay();
              resList2.push({
                date: formatTime(current),
                ...businessList[currentDay],
              });
              resList[formatTime(current)] = { ...businessList[currentDay] };
            }

            holidaysList.forEach(
              ({ manageDate, isFullDayOff, openHr, closeHr }: HolidaysHour) => {
                const holiday = resList2.find(
                  ({ date }) => date === manageDate,
                );
                if (holiday) {
                  holiday.isFullDayOff = isFullDayOff;
                  holiday.open = openHr;
                  holiday.close = closeHr;
                }
                resList[manageDate] = {
                  isFullDayOff,
                  open: openHr,
                  close: closeHr,
                };
              },
            );
            setWorkingDays(resList2);
          }
        },
      );
    }
  }, [shopId, month]);

  return (
    <div className="sub_calendar">
      <div className="sub_calendar_top">
        <button onClick={() => handleChangeMonth(calcDate("M", -1, month))}>
          <img src={iconArrowLeft} alt="" />
        </button>
        <h3>
          {formatTime(month, "YYYY")
            .split("")
            .map((c, i) => (
              <td key={i} className="spoqa">
                <p>{c}</p>
              </td>
            ))}
          <td>년</td>
          {formatTime(month, "MM")
            .split("")
            .map((c, i) => (
              <td key={i} className="spoqa">
                <p>{c}</p>
              </td>
            ))}
          <td>월</td>
        </h3>
        <button onClick={() => handleChangeMonth(calcDate("M", 1, month))}>
          <img src={iconArrowRight} alt="" />
        </button>
      </div>
      <div className="sub_calendar_tb">
        <table>
          <thead>
            <tr>
              {DAYS_OF_WEEK.map((day, index) => (
                <td
                  className={`${
                    index === 0 ? "sun" : index === 6 ? "sat" : ""
                  }`}
                  key={index}
                >
                  <p>{day}</p>
                </td>
              ))}
            </tr>
          </thead>
          {generate()}
        </table>
      </div>
    </div>
  );
};

export default Calendar;
