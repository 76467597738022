import React, { useState } from "react";
import icon20 from "@/assets/icons/icon_20.png";
import icon24 from "@/assets/icons/ico_24px.png";
import iconCopy from "@/assets/icons/ico_copy_16.png";
import iconStar from "@/assets/icons/icon_star.png";
import {
  formatTime,
  getKoreanDayOfWeek,
  numberWithCommas,
} from "@/utils/utils";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  closeBottomSheet,
  openBottomSheet,
} from "@/features/bottom-sheet/bottomSheetSlice";
import FindShop from "@components/common/find-shop/FindShop";
import { BottomSheet } from "@components/index";
import Calendar from "@components/common/calendar/Calendar";
import iconLeft from "@/assets/icons/icon_left.png";
import * as APIS from "@/services/apis";
import { hideLoading, showLoading } from "@/features/loading/loadingSlice";
import { showToast } from "@/features/toast/toastSlice";
import * as styles from "../styles.css";

interface DetailInfoProps {
  detail: any;
  setDetail: any;
}

// TODO: 총 감정 중량 관련 리스트 보여줄때 여러개 아이템 넣어봐서 스크롤 적용하기.

const DetailInfo: React.FC<DetailInfoProps> = ({ detail, setDetail }) => {
  const {
    id,
    requestMethod,
    number,
    shop,
    appraisalRequestStatus,
    appraisalItemDtoList,
    appraisalWeightGram,
    assetType,
    visitDate,
    statusType,
    collectAddress,
    collectAddressDetail,
  } = detail;
  const dispatch = useDispatch();

  let completeData = appraisalRequestStatus?.filter(
    (x: any) => x.statusType === "EXCHANGE_COMPLETE",
  )[0];
  const [activeBottomSheet, setActiveBottomSheet] = useState<string>("shop");
  const [isShop, setIsShop] = useState(false);
  const handleChangeBottomSheet = (type: string) => {
    setActiveBottomSheet(type);
    dispatch(openBottomSheet());
  };

  const handleSelectShop = (result: any) => {
    setDetail((prevDetail: any) => ({
      ...prevDetail,
      shop: result,
      visitDate: null,
    }));
    setIsShop(true);
    setActiveBottomSheet("calendar");
  };

  const handleSelectDate = (result: any) => {
    setDetail((prevDetail: any) => ({
      ...prevDetail,
      visitDate: result.visitDate,
    }));
    // setDetail({ ...detail, visitDate: result.visitDate });
    dispatch(showLoading());
    if (id) {
      APIS.putAppraisalRequestsInfo(id, {
        shopId: shop.id,
        visitDate: formatTime(result.visitDate),
      })
        .then(({ data: { success, message } }) => {
          if (success) {
            if (isShop) {
              dispatch(
                showToast({
                  message: "방문 대리점이 변경되었습니다.",
                  icon: "success",
                }),
              );
            } else {
              dispatch(
                showToast({
                  message: "방문일이 변경되었습니다.",
                  icon: "success",
                }),
              );
            }
          } else {
            dispatch(showToast({ message, icon: "error" }));
          }
        })
        .finally(() => {
          dispatch(hideLoading());
          setIsShop(false);
        });
    }
    dispatch(closeBottomSheet());
  };
  const handleClickClose = () => {
    if (isShop) {
      setActiveBottomSheet("shop");
    } else {
      dispatch(closeBottomSheet());
    }
  };

  const handleClickPhone = (phoneNumber: string) => {
    if (phoneNumber) {
      window.location.href = "tel:" + phoneNumber;
    } else {
      dispatch(
        showToast({
          message: "매장 전화번호가 등록되지 않았습니다.",
          icon: "caution",
        }),
      );
    }
  };

  const handleClickAddressCopy = () => {
    const fullAddress = `${shop?.address} ${shop?.addressDetail}`;
    navigator.clipboard
      .writeText(fullAddress)
      .then(() => {
        dispatch(
          showToast({
            message: "주소가 클립보드에 복사되었습니다.",
            icon: "success",
          }),
        );
      })
      .catch(() => {
        dispatch(
          showToast({ message: "주소 복사에 실패했습니다.", icon: "error" }),
        );
      });
  };

  return (
    <>
      {statusType === "VERIFY_COMPLETE" || statusType === "RETURN_REQUEST" ? (
        <div className="shadow88 cont_box">
          <h3 className="shadow88_tit fc-bl03">
            <img src={iconStar} />
            감정 결과
          </h3>
          <div className="shadow88_ul02">
            <ul>
              <li>
                <h3>감정 대리점</h3>
              </li>
              <li>
                <h3>{shop?.name}</h3>
              </li>
            </ul>
          </div>
          <div className="stroke-line"></div>
          <div className="shadow88_ul01">
            <h3>감정 내용</h3>
            <div className="shadow88_acco">
              <div className="sell_sub_accordion sell_sub_vertical">
                <ul>
                  <li className="acco_li">
                    <input
                      type="checkbox"
                      id="checkbox-1"
                      name="checkbox-accordion"
                    />
                    <label htmlFor="checkbox-1">
                      <div className="btn_cx_down"></div>
                      <div className="btn_cx_up"></div>
                    </label>
                    <div className="sell_sub_check">
                      <ul className="mb16">
                        <li className="acco_li01">순도</li>
                        <li className="acco_li02">
                          {assetType === "GOLD" ? "24K" : "999.9‰"}
                        </li>
                      </ul>
                      <ul className="mb16">
                        <li className="acco_li01">총 감정 중량</li>
                        <li className="acco_li02">
                          {numberWithCommas(appraisalWeightGram)}g
                        </li>
                      </ul>
                    </div>
                    <div className="sell_sub_content">
                      {appraisalItemDtoList.map((item: any, index: number) => {
                        return (
                          <ul className={index === 0 ? "mb08" : ""}>
                            <li className="acco_li01">
                              <p>{item?.productName || ""}</p>
                            </li>
                            <li className="acco_li02">
                              <h3>{numberWithCommas(item?.gram)}g</h3>
                            </li>
                          </ul>
                        );
                      })}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : statusType === "EXCHANGE_COMPLETE" ? (
        <div className="shadow88 cont_box">
          <h3 className="shadow88_tit fc-bl03">
            <img src={iconStar} />
            감정 결과
          </h3>
          <div className="shadow88_ul02">
            <h3>감정 대리점, 승인일</h3>
            <ul>
              <li className="shadow88_li01">
                <p>대리점</p>
              </li>
              <li className="shadow88_li02">
                <p>{shop.name}</p>
              </li>
            </ul>
            <ul>
              <li className="shadow88_li01">
                <p>승인 일자</p>
              </li>
              <li className="shadow88_li02">
                <p>{formatTime(completeData.updatedAt, "YYYY년 MM월 DD일")} </p>
              </li>
            </ul>
          </div>
          <div className="stroke-line"></div>
          <div className="shadow88_ul01">
            <h3>감정 내용</h3>
            <div className="shadow88_acco">
              <div className="sell_sub_accordion sell_sub_vertical">
                <ul>
                  <li className="acco_li">
                    <input
                      type="checkbox"
                      id="checkbox-1"
                      name="checkbox-accordion"
                    />
                    <label htmlFor="checkbox-1">
                      <div className="btn_cx_down"></div>
                      <div className="btn_cx_up"></div>
                    </label>
                    <div className="sell_sub_check">
                      <ul className="mb16">
                        <li className="acco_li01">순도</li>
                        <li className="acco_li02">
                          {assetType === "GOLD" ? "24K" : "999.9‰"}
                        </li>
                      </ul>
                      <ul className="mb16">
                        <li className="acco_li01">총 감정 중량</li>
                        <li className="acco_li02">
                          {numberWithCommas(appraisalWeightGram)}g
                        </li>
                      </ul>
                    </div>
                    <div className="sell_sub_content">
                      {appraisalItemDtoList.map((item: any, index: number) => {
                        return (
                          <ul className={index === 0 ? "mb08" : ""}>
                            <li className="acco_li01">
                              <p>{item?.productName || ""}</p>
                            </li>
                            <li className="acco_li02">
                              <h3>{numberWithCommas(item?.gram)}g</h3>
                            </li>
                          </ul>
                        );
                      })}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="shadow88 cont_box">
          <h3 className="shadow88_tit">
            <img src={icon20} />
            {requestMethod === "SIMPLE_DELIVERY"
              ? "택배 감정 접수 정보"
              : "접수 내역"}
          </h3>
          <div className="cont_number">
            <ul>
              <li className="cont_numli01">
                <p>접수번호</p>
              </li>
              <li className="cont_numli02">
                <p>{number}</p>
              </li>
            </ul>
          </div>
          {requestMethod === "SIMPLE_DELIVERY" ? (
            <>
              {statusType === "DELIVERY" ||
              statusType === "REQUEST" ||
              statusType === "VERIFY_START" ? (
                <>
                  {statusType === "REQUEST" && (
                    <span className="cont_noti">
                      <img src={icon24} />
                      아래의 주소로 수거 예정입니다.
                    </span>
                  )}
                  <div className="shadow88_ul01">
                    <h3>택배 수거 주소</h3>
                    <ul>
                      <li className="shadow88_li01">
                        <p>주소</p>
                      </li>
                      <li className="shadow88_li02">
                        <p>
                          {collectAddress}
                          <br />
                          {`(${collectAddressDetail})`}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="stroke-line" />
                  <div className="shadow88_ul01">
                    <h3>감정센터</h3>
                    <ul>
                      <li className="shadow88_li01">
                        <p>주소</p>
                      </li>
                      <li className="shadow88_li02">
                        <p>
                          서울특별시 종로구 돈화문로 5가길 1<br />
                          피카디리플러스 8층 한국금거래소디지털에셋
                          <br />
                          감정센터 앞
                        </p>
                      </li>
                    </ul>
                  </div>
                </>
              ) : (
                <>
                  {statusType === "REQUEST" && (
                    <span className="cont_noti">
                      <img src={icon24} />
                      아래의 주소로 수거 예정입니다.
                    </span>
                  )}
                  <div className="shadow88_ul01">
                    <h3>택배 수거 주소</h3>
                    <ul>
                      <li className="shadow88_li01">
                        <p>주소</p>
                      </li>
                      <li className="shadow88_li02">
                        <p>
                          {collectAddress}
                          <br />
                          {`(${collectAddressDetail})`}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="stroke-line" />
                  <div className="shadow88_ul01">
                    <h3>감정센터</h3>
                    <ul>
                      <li className="shadow88_li01">
                        <p>주소</p>
                      </li>
                      <li className="shadow88_li02">
                        <p>
                          서울특별시 종로구 돈화문로 5가길 1<br />
                          피카디리플러스 8층 한국금거래소디지털에셋
                          <br />
                          감정센터 앞
                        </p>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="shadow88_ul01">
                <ul>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h3>방문 대리점</h3>
                    <img
                      src={iconCopy}
                      style={{ width: 24, height: 24 }}
                      onClick={handleClickAddressCopy}
                    />
                  </div>
                  {statusType === "REQUEST" && (
                    <span
                      style={{
                        color: "#007AFF",
                        fontSize: 12,
                        fontFamily: "Noto Sans KR",
                        fontWeight: "500",
                        textDecoration: "underline",
                        wordWrap: "break-word",
                        alignContent: "center",
                      }}
                      onClick={() => handleChangeBottomSheet("shop")}
                    >
                      방문 대리점 변경
                    </span>
                  )}
                </ul>
                <ul>
                  <li className="shadow88_li01">
                    <p>대리점</p>
                  </li>
                  <li className="shadow88_li02">
                    <h3>{shop?.name || "-"}</h3>
                  </li>
                </ul>
                <ul>
                  <li className="shadow88_li01">
                    <p>주소</p>
                  </li>
                  <li className="shadow88_li02">
                    <p>
                      {shop?.address}
                      <br />
                      {`(${shop?.addressDetail})`}
                    </p>
                  </li>
                </ul>
              </div>
              <div className="stroke-line" />
              <div className="shadow88_ul01">
                <ul>
                  <h3>방문일</h3>
                  {statusType === "REQUEST" && (
                    <span
                      style={{
                        color: "#007AFF",
                        fontSize: 12,
                        fontFamily: "Noto Sans KR",
                        fontWeight: "500",
                        textDecoration: "underline",
                        wordWrap: "break-word",
                        alignContent: "center",
                      }}
                      onClick={() => handleChangeBottomSheet("calendar")}
                    >
                      방문일 변경
                    </span>
                  )}
                </ul>
                <ul>
                  <li className="shadow88_li01">
                    <p>방문 일자</p>
                  </li>
                  <li className="shadow88_li02">
                    <p>
                      {visitDate
                        ? `${moment(visitDate).format(
                            "YYYY년 MM월 DD일",
                          )} ${getKoreanDayOfWeek(visitDate)}`
                        : "-"}
                    </p>
                  </li>
                </ul>
                <ul>
                  <li className="shadow88_li01">
                    <p>영업시간</p>
                  </li>
                  <li
                    className="shadow88_li02"
                    onClick={() => handleClickPhone(shop.phoneNumber)}
                  >
                    <p
                      style={{
                        color: "#007AFF",
                        fontSize: 12,
                        fontFamily: "Noto Sans KR",
                        fontWeight: "500",
                        textDecoration: "underline",
                        wordWrap: "break-word",
                        alignContent: "center",
                      }}
                    >
                      대리점 문의
                    </p>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
      )}

      <BottomSheet>
        {activeBottomSheet === "calendar" ? (
          <div className="sub_bottom cal_tab">
            <div className="sub_bottom_wrap">
              <div className="sub_top01">
                <h3 className="sub_bottom_tit">
                  <img
                    src={iconLeft}
                    alt=""
                    className="icon_back"
                    onClick={handleClickClose}
                  />
                  예약일 선택
                </h3>
              </div>
              <Calendar
                reservationData={{
                  shopId: shop.id,
                  visitDate: visitDate,
                }}
                setReservationData={handleSelectDate}
              />
            </div>
            <div className={styles.subBtnWrap}>
              <button className={styles.subWrap02Btn}>선택</button>
            </div>
          </div>
        ) : (
          <FindShop onSelect={handleSelectShop} service="appraisal" />
        )}
      </BottomSheet>
    </>
  );
};

export default DetailInfo;
