import { closeModal, openModal } from "@/features/modal/modalSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import iconCau from "@/assets/icons/ico_56px_cau.png";
import { showToast } from "@/features/toast/toastSlice";
import { hideLoading, showLoading } from "@/features/loading/loadingSlice";
import * as APIS from "@/services/apis";
import { numberWithCommas } from "@/utils/utils";
import { useRef } from "react";
import { routes } from "@/routes";
import colors from "@assets/styles/colors.css";

const ActionButtonArea = ({ detail }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isClick = useRef(false);

  const completeFetch = () => {
    isClick.current = false;
    dispatch(hideLoading());
    navigate(location.pathname, { state: { id: detail.id }, replace: true });
  };

  const handleClickCancel = (type: string) => {
    dispatch(
      openModal(
        <div className="bg_gr_wrap">
          <div className="modal_wrap">
            <img src={iconCau} alt="caution" />
            <h3>접수하신 모든 정보가 사라집니다.</h3>
            <p>{`${
              type === "REQUEST" ? "접수를" : "감정을"
            } 취소하시겠어요?`}</p>
            <div className="modal_btn">
              <button
                className="bg_gr_btn"
                onClick={() => requestCancel(type)}
                style={{ backgroundColor: "#ffe5e5", color: "#f22" }}
              >
                {`${type === "REQUEST" ? "접수" : "감정"}취소`}
              </button>
              <button
                className="bg_bk_btn"
                onClick={handleCloseModal}
                style={{ backgroundColor: "#eee", color: "#222" }}
              >
                계속하기
              </button>
            </div>
          </div>
        </div>,
      ),
    );
  };

  // 접수 취소
  const requestCancel = (type: string) => {
    dispatch(showLoading());
    if (type === "REQUEST") {
      APIS.deleteAppraisalRequest(detail && detail?.id)
        .then(({ data: { success, message } }) => {
          if (success) {
            dispatch(
              showToast({
                message: "대리점 감정 접수가 취소되었습니다.",
                icon: "caution",
              }),
            );
            navigate(-1);
          } else {
            dispatch(showToast({ message, icon: "error" }));
          }
        })
        .finally(() => {
          dispatch(hideLoading());
          dispatch(closeModal());
        });
    } else {
      const param = {
        returnShopId: detail?.shop?.id,
        status: "RETURN_REQUEST",
      };
      APIS.putAppraisalRequestsStatus(detail && detail?.id, param)
        .then(({ data: { success, message } }) => {
          if (success) {
            dispatch(
              showToast({
                message: "감정이 취소되었습니다.",
                icon: "caution",
              }),
            );
            navigate(-1);
          } else {
            dispatch(showToast({ message, icon: "error" }));
          }
        })
        .finally(() => {
          dispatch(hideLoading());
          dispatch(closeModal());
        });
    }
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };
  const completeExchange = async () => {
    if (!isClick.current) {
      isClick.current = true;
      dispatch(showLoading());

      const serviceBreak = await APIS.getServiceBreak();
      if (serviceBreak) {
        dispatch(showToast({ message: serviceBreak, icon: "caution" }));
        completeFetch();
        return;
      }

      APIS.putAppraisalRequestsStatus(detail.id, {
        status: "EXCHANGE_COMPLETE",
      }).then(({ data: { success, data, message } }) => {
        if (success) {
          pendingExchange(1);
        } else {
          dispatch(showToast({ message, icon: "error" }));
          completeFetch();
        }
      });
    }
  };
  const pendingExchange = (count: number) => {
    setTimeout(() => {
      APIS.getAppraisalRequestPending(detail.id).then(
        ({ data: { success } }) => {
          if (success) {
            completeFetch();
            dispatch(
              showToast({ message: "교환 완료 되었습니다.", icon: "success" }),
            );
          } else if (count === 10) {
            completeFetch();
            dispatch(
              showToast({ message: "교환 처리 중 입니다.", icon: "success" }),
            );
          } else {
            pendingExchange(count + 1);
          }
        },
      );
    }, 1000);
  };

  return (
    <div>
      {detail.statusType === "REQUEST" && (
        <button
          className="cancel_btn"
          onClick={() => handleClickCancel("REQUEST")}
        >
          접수 취소
        </button>
      )}
      {detail.statusType === "VERIFY_COMPLETE" && (
        <>
          <button
            className="sell_btn"
            onClick={completeExchange}
            style={{
              backgroundColor: colors.buttonColor,
            }}
          >
            <span>
              {detail.assetType?.replace("GOLD", "금")?.replace("SILVER", "은")}{" "}
              {numberWithCommas(detail.appraisalWeightGram)}g
            </span>{" "}
            받기
          </button>
          <span
            className="btn_h36"
            onClick={() => handleClickCancel("VERIFY_COMPLETE")}
          >
            감정 취소
          </span>
        </>
      )}
      {detail.statusType === "EXCHANGE_COMPLETE" && (
        <button
          className="sell_btn"
          onClick={() => {
            navigate(routes.tradeAsset, {
              state: { tradeType: "SELL" },
            });
          }}
          style={{
            backgroundColor: colors.buttonColor,
          }}
        >
          자산 팔래요
        </button>
      )}
    </div>
  );
};

export default ActionButtonArea;
