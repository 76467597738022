import useCustomQuery from "@/hooks/queries/useCustomQuery";
import * as APIS from "@/services/apis";
import {
  showLoading,
  selectLoading,
  hideLoading,
} from "@/features/loading/loadingSlice";
import { useDispatch } from "react-redux";
import { routes } from "@/routes";

interface IPrepriceProps {
  goodsId: string;
  useGram: number;
  qty: number;
}

interface IPreprice {
  message: string;
  isAvailable: boolean;
  price: number;
  buyPrice: number;
  balanceGrams: number;
}
const usePreprice = (params: IPrepriceProps) => {
  const dispatch = useDispatch();

  const fetchDetails = async () => {
    dispatch(showLoading());
    try {
      const response = await APIS.getPreprice(params);
      dispatch(hideLoading());
      return response.data.data as IPreprice;
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  const { data, error, isLoading, refetch } = useCustomQuery<IPreprice>(
    [routes.buyGoodsDetail, params],
    fetchDetails,
  );

  return { Preprice: data, isLoading, error, PrepriceRefetch: refetch };
};
export default usePreprice;
