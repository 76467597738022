import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import iconLeft from "../../assets/icons/icon_left.png";
import ic_arrow_left from "../../assets/icons/icon_arrow_left_c.png";
import { ButtonBase } from "@mui/material";
import useCryptoKeyboard from "@/hooks/useCryptoKeyboard";
import {
  postRequestTrade,
  postWithdrawal,
  putPurchase,
  payStorageFee,
} from "@/services/apis";
import { useDispatch } from "react-redux";
import { showToast } from "@/features/toast/toastSlice";
import { closeBottomSheet } from "@/features/bottom-sheet/bottomSheetSlice";
import { hideLoading, showLoading } from "@/features/loading/loadingSlice";
import { routes } from "@/routes";

import * as APIS from "@/services/apis";
import usePendingCheck from "@/hooks/usePendingCheck";
import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import usePlainKeyboard from "@/hooks/usePlainKeyboard";

type Props = {};

interface WithdrawalData {
  tradeId: string | null;
  tradeStatus:
    | "TRADE_REQUEST"
    | "TRADE_COMPLETE"
    | "TRADE_CANCEL"
    | "TRADE_FAIL";
  tradeType: "WITHDRAW";
  tradeNumber: string | null;
  assetType: string | null;
  tradeGram: number;
  pureTradeKrw: number;
  tradePoint: number;
  pricePerGram: number;
  userFee: number;
  afterKrw: number;
  afterPoint: number;
  afterGold: number;
  afterSilver: number;
  requestAt: string | null;
  completedAt: string | null;
  rawId: number;
  yourName: string | null;
  addTradeType: string | null;
  message: string;
}
interface PurchaseData {
  status: string;
  transId: number;
}

/**
 * 1. 실물인출 현금 결제
 * 2. 매수 / 매도
 * 3. 결제 비밀번호 등록 / 변경
 * 4. ???
 */

function SecureKeypad({}: Props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);
  const userVersion = auth.version;
  const isClick = useRef(false);
  const type = state?.type || "";
  const payload = state?.payload || {};
  const [keyArr, setKeyArr] = useState<number[][]>([]);
  const { secured, actions: secureActions } =
    userVersion === "V1" ? usePlainKeyboard() : useCryptoKeyboard();

  useLayoutEffect(() => {
    shuffle();
  }, []);

  const shuffle = () => {
    const newArr = Array.from({ length: 10 }, (_, i) => i).sort(
      () => Math.random() - 0.5,
    );

    setKeyArr(Array.from({ length: 4 }).map(() => newArr.splice(0, 3)));
  };

  const pendingCheck = usePendingCheck();

  useEffect(() => {
    if (secured.count === 6 && !isClick.current) {
      isClick.current = true;
      dispatch(showLoading());
      if (type === "TRADE") {
        const param = { ...payload };
        param.password = secured.result;
        postRequestTrade(param)
          .then(({ data: { success, message } }) => {
            if (success) {
              dispatch(
                showToast({ message: "주문 완료되었습니다.", icon: "success" }),
              );
              navigate(-1);
              dispatch(closeBottomSheet());
            } else {
              dispatch(showToast({ message, icon: "error" }));
              secureActions.clearAll();
            }
          })
          .finally(() => {
            dispatch(hideLoading());
            isClick.current = false;
          });
      } else if (type === "WITHDRAW") {
        const param = { ...payload };
        param.securityPasswd = secured.result;
        postWithdrawal(param)
          .then(({ data: { success, data, message } }) => {
            if (success) {
              pendingCompleteWithdrawalCheck(data);
            } else {
              dispatch(showToast({ message, icon: "error" }));
              secureActions.clearAll();
            }
          })
          .finally(() => {
            dispatch(hideLoading());
            isClick.current = false;
          });
      } else if (type === "BUY") {
        const param = { ...payload };
        param.finPassword = secured.result;
        param.depositMethod = "NONE";
        putPurchase(param.goodsId, param)
          .then(({ data: { success, data, message } }) => {
            if (success) {
              pendingCompletePurchaseCheck(data.goodsMallRequestId);
            } else {
              secureActions.clearAll();
              dispatch(hideLoading());
              dispatch(showToast({ message, icon: "error" }));
            }
          })
          .finally(() => {
            isClick.current = false;
          });
      } else if (type === "STORAGE_FEE") {
        const param = { ...payload };
        param.finPassword = secured.result;
        payStorageFee(param)
          .then(({ data: { success, message } }) => {
            if (success) {
              dispatch(
                showToast({
                  message: "보관료 납부가 완료되었습니다.",
                  icon: "success",
                }),
              );
              navigate(-1);
            } else {
              dispatch(showToast({ message, icon: "error" }));
              secureActions.clearAll();
            }
          })
          .finally(() => {
            dispatch(hideLoading());
            isClick.current = false;
          });
      }
    }
  }, [secured.count]);

  const pendingCompletePurchaseCheck = (goodsMallRequestId: string) => {
    pendingCheck<PurchaseData>({
      checkFn: () => APIS.getPurchaseCheck(goodsMallRequestId),
      successCondition: (data) => data.status === "APPROVAL",
      successMessage: "결제 완료되었습니다.",
      processingMessage: "결제 요청 처리 중 입니다.",
      failureMessage: "결제에 실패하였습니다.",
      navigateOnSuccess: (data) => {
        navigate(`${routes.purchaseSuccess}/${data.transId}`, {
          replace: true,
        });
        secureActions.clearAll();
      },
      navigateOnProcessing: () => {
        dispatch(hideLoading());
        navigate(routes.main, { replace: true });
      },
      navigateOnFailure: () => {
        secureActions.clearAll();
        navigate(-1);
        dispatch(hideLoading());
      },
    });
  };

  const pendingCompleteWithdrawalCheck = (withdrawId: string) => {
    pendingCheck<WithdrawalData>({
      checkFn: () => APIS.getWithdrawalCheck(withdrawId),
      successCondition: (data) => data.tradeStatus === "TRADE_COMPLETE",
      processingCondition: (data) => data.tradeStatus === "TRADE_REQUEST",
      successMessage: "출금이 완료되었습니다.",
      processingMessage: "출금 처리 중 입니다.",
      failureMessage: "출금에 실패하였습니다.",
      navigateOnSuccess: (data) => {
        navigate(routes.withdrawComplete, {
          state: {
            pureTradeKrw: data.pureTradeKrw, // 출금액
            userFee: data.userFee, // 출금 수수료
            afterKrw: data.afterKrw, // 출금 후 잔액
          },
          replace: true,
        });
        secureActions.clearAll();
      },
      navigateOnProcessing: () => navigate(routes.main, { replace: true }),
      navigateOnFailure: () => secureActions.clearAll(),
    });
  };

  return (
    <div className="keypad_page">
      <div className="keypad_wrap">
        <div className="sub_top">
          <ButtonBase
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={iconLeft} alt="" className="icon_back" />
          </ButtonBase>
        </div>
        <div className="keypad_top">
          <h3>결제 비밀번호 입력</h3>
          <p>비밀번호 6자리를 입력해주세요.</p>
          {/* 실패시 문구 */}
          {/* <p className="fail">결제 비밀번호가 일치하지 않습니다.</p> */}
          <ul>
            {Array.from({ length: 6 }).map((_, i) => (
              <li
                className={secured.count > i ? "on" : ""}
                key={i.toString()}
              />
            ))}
          </ul>
        </div>
        <div className="keypad">
          <table>
            <tbody>
              {keyArr.map((row, i) => {
                return (
                  <tr key={i.toString()}>
                    {i === 3 && (
                      <td
                        onClick={() => {
                          secureActions.clearAll();
                        }}
                      >
                        <ButtonBase>
                          <p className="on">전체 삭제</p>
                        </ButtonBase>
                      </td>
                    )}

                    {row.map((key, j) => {
                      return (
                        <td key={j.toString()}>
                          <ButtonBase
                            onClick={async () => {
                              secureActions.pressKey(key);
                            }}
                          >
                            {key}
                          </ButtonBase>
                        </td>
                      );
                    })}

                    {i === 3 && (
                      <td
                        onClick={() => {
                          secureActions.removeOne();
                        }}
                      >
                        <ButtonBase>
                          <img src={ic_arrow_left} alt="삭제" />
                        </ButtonBase>
                      </td>
                    )}
                  </tr>
                );
              })}
              {/* {[1, 4, 7, 10].map((item: number) => {
                if (item < 10) {
                  return (
                    <tr key={`keypad_row_${item}`}>
                      <td>
                        <ButtonBase>{item}</ButtonBase>
                      </td>
                      <td>
                        <ButtonBase>{item + 1}</ButtonBase>
                      </td>
                      <td>
                        <ButtonBase>{item + 2}</ButtonBase>
                      </td>
                    </tr>
                  );
                } else {
                  return (
                    <tr>
                      <td>
                        <ButtonBase>
                          <p className="on">전체 삭제</p>
                        </ButtonBase>
                      </td>
                      <td>
                        <ButtonBase>0</ButtonBase>
                      </td>
                      <td>
                        <ButtonBase>
                          <img src={ic_arrow_left} />
                        </ButtonBase>
                      </td>
                    </tr>
                  );
                }
              })} */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SecureKeypad;
