import { ButtonBase, Tab, Tabs } from "@mui/material";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { openBottomSheet } from "@/features/bottom-sheet/bottomSheetSlice";
import { routes } from "@/routes";
import iconSearch from "@assets/icons/ico_search_24.png";
import tradeArrow from "@assets/icons/ico_tradeArrow.png";
import btnMenu from "@assets/images/btn_menu02.png";
import btnTop from "@assets/images/btn_top_48px.png";
import fonts from "@assets/styles/fonts";
import Banners from "@components/common/banners/Banners";
import HamburgerMenu from "@pages/home/components/HamburgerMenu";
import BottomNavigation from "@pages/sell/components/BottomNavigation";
import { useDispatch } from "react-redux";
import CategoryBottomSheet from "./components/CategoryBottomSheet";
import GoodsCardInfo from "./components/GoodsCardInfo";
import GoodsStackInfo from "./components/GoodsStackInfo";
import useGoodsCategory, { GoodsCategoryInfo } from "./hooks/useGoodsCategory";
import useGoodsGroup, { GoodsGroupInfo } from "./hooks/useGoodsGroup";
import useGoodsList, { GoodsListInfo } from "./hooks/useGoodsList";
import * as styles from "./styles.css";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "@/services/storageService";
import { scrollToRoot } from "@/utils/utils";

const DROP_DOWN_ITEMS = [
  "판매순",
  "신상품순",
  "낮은 가격순",
  "높은 가격순",
  "할인율순",
] as const;

const lookingCategoryItems = (
  tab: number | string,
  goodsCategoryData: GoodsCategoryInfo | undefined,
  goodsListData: GoodsListInfo | undefined,
  goodsGroupData: GoodsGroupInfo | undefined,
) => {
  if (!goodsCategoryData || !goodsListData || !goodsGroupData) {
    return undefined;
  }
  if (tab === "전체") {
    return goodsListData;
  }
  let matchCategory:
    | GoodsCategoryInfo[number]
    | GoodsGroupInfo[number]
    | undefined;

  if (tab === 0) {
    matchCategory = goodsGroupData.find(
      (item) => item.goodsGroupName === "인기 상품",
    );
  } else {
    matchCategory = goodsCategoryData.find(
      (item) => item.goodsCategoryId === tab,
    );
  }
  const result: GoodsListInfo = [];
  if (matchCategory) {
    matchCategory.goodsIdList.forEach((id) => {
      const matchedItem = goodsListData.find((item) => item.goodsId === id);
      if (matchedItem) {
        result.push(matchedItem);
      }
    });
  }
  return result;
};

const sortByDropDown = (
  toShowGoodsList: GoodsListInfo | undefined,
  dropDownItem: (typeof DROP_DOWN_ITEMS)[number],
) => {
  switch (dropDownItem) {
    case "낮은 가격순": {
      return toShowGoodsList?.sort((a, b) => a.sellingPrice - b.sellingPrice);
    }
    case "높은 가격순": {
      return toShowGoodsList?.sort((a, b) => b.sellingPrice - a.sellingPrice);
    }
    case "신상품순": {
      return toShowGoodsList?.sort(
        (a, b) =>
          new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
      );
    }
    case "판매순": {
      return toShowGoodsList?.sort((a, b) => b.salesCount - a.salesCount);
    }
    case "할인율순": {
      return toShowGoodsList?.sort((a, b) => b.discountRate - a.discountRate);
    }
  }
};

interface DropDown {
  isOpen: boolean;
  item: (typeof DROP_DOWN_ITEMS)[number];
}
const useDropDown = () => {
  const [dropDown, setDropDown] = useState<DropDown>({
    isOpen: false,
    item: "판매순",
  });
  const dropDownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropDownRef.current &&
        !dropDownRef.current.contains(event.target as Node)
      ) {
        setDropDown({ isOpen: false, item: dropDown.item });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropDown.item]);

  return { dropDown, setDropDown, dropDownRef } as const;
};

const Buy = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [tab, setTab] = useState(0);
  const { dropDown, setDropDown, dropDownRef } = useDropDown();

  const { data: goodsCategoryData, isFetching } = useGoodsCategory();
  const { data: goodsListData } = useGoodsList();
  const { data: goodsGroupData } = useGoodsGroup();

  const toShowGoodsList = lookingCategoryItems(
    tab,
    goodsCategoryData,
    goodsListData,
    goodsGroupData,
  );
  const sortedGoodsList = sortByDropDown(toShowGoodsList, dropDown.item);
  const requestedTime = isFetching
    ? "조회 중"
    : `${new Date().getHours()}시 ${new Date().getMinutes()}분 기준`;

  const handleTab = (newTab: number) => {
    setTab(newTab);
  };
  const handleClickSearch = () => {
    navigate(routes.buySearch);
  };
  const handleDropDown = () => {
    setDropDown((prev) => ({
      ...prev,
      isOpen: !prev.isOpen,
    }));
  };

  useEffect(() => {
    scrollToRoot({ isSmooth: true });
  }, [tab, dropDown.item]);

  useLayoutEffect(() => {
    const isFromBuy = getLocalStorageItem("fromBuy");
    if (isFromBuy) {
      setTab(isFromBuy);
      localStorage.removeItem("kumbang.fromBuy");
    }
  }, []);

  return (
    <div>
      <header className="sub_header02">
        <img
          src={iconSearch}
          alt=""
          className="search"
          onClick={handleClickSearch}
        />
        <HamburgerMenu />
      </header>
      <div className="sub02_wrap">
        <div className="buy_top">
          <h3>쇼핑</h3>
          <ButtonBase
            className="buy_btn"
            onClick={() => {
              navigate(routes.tradeAsset, {
                state: { tradeType: "BUY" },
              });
              setLocalStorageItem("tradingAssetType", "GOLD");
            }}
          >
            <p>
              <img src={tradeArrow} alt="" /> 자산 살래요
            </p>
          </ButtonBase>
        </div>
        <div className={styles.sidePadding}>
          <Banners />
        </div>
        <div
          className="buy_main01_menu"
          style={{
            position: "sticky",
            top: 0,
            zIndex: 10,
            backgroundColor: "#FAFEFE",
          }}
        >
          <div className="buy_main01">
            {/* <div className="list_bt">
              <img
                src={btnMenu}
                alt=""
                className="menu"
                onClick={() => {
                  dispatch(openBottomSheet());
                }}
              />
            </div>
            <div className="buy_list_tab">
              <ul>
                {goodsCategoryData?.map((item) => {
                  return (
                    <li
                      key={item.goodsCategoryId}
                      value={item.kind}
                      className={tab === item.kind ? "on" : ""}
                      onClick={(e) => handleTab(e, item.kind)}
                    >
                      <p>{item.kind}</p>
                    </li>
                  );
                })}
                
              </ul>
            </div> */}
            <div
              style={{
                display: "flex",
                padding: "0 8px",
              }}
            >
              <Tabs
                value={tab}
                TabIndicatorProps={{
                  style: {
                    background: "#00BAFF",
                  },
                }}
                sx={{
                  "& .MuiTab-root": {
                    padding: "12px 3px",
                    width: "80px",
                  },
                  "& .Mui-disabled": {
                    width: "20px",
                  },
                }}
                onChange={(_, value) => {
                  handleTab(value);
                }}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                TabScrollButtonProps={{
                  style: {
                    // width: "32px",
                    height: "48px",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                }}
              >
                <Tab
                  label={"인기"}
                  value={0}
                  sx={{
                    "&.Mui-selected": {
                      color: "#00BAFF",
                      fontFamily: fonts.notoSansKRBold,
                    },
                  }}
                />
                {goodsCategoryData?.map((category) => {
                  return (
                    <Tab
                      key={category.goodsCategoryId}
                      label={category.kind}
                      value={category.goodsCategoryId}
                      sx={{
                        "&.Mui-selected": {
                          color: "#00BAFF",
                          fontFamily: fonts.notoSansKRBold,
                        },
                      }}
                    />
                  );
                })}
              </Tabs>
              <div style={{ width: "84px" }} />
              <div className="list_bt2">
                <img
                  src={btnMenu}
                  alt=""
                  className="menu"
                  onClick={() => {
                    dispatch(openBottomSheet());
                  }}
                />
              </div>
            </div>
            <div className="buy_main01_top">
              <span className="time">{requestedTime}</span>
              {tab !== 0 && (
                <div className="dropdown_filter" ref={dropDownRef}>
                  <div className="drop-down">
                    <div className="selected">
                      <a onClick={handleDropDown}>
                        <span>{dropDown.item}</span>
                      </a>
                    </div>
                    <div className="options">
                      {dropDown.isOpen && (
                        <ul>
                          {DROP_DOWN_ITEMS.map((item) => {
                            return (
                              <li key={item}>
                                <a
                                  onClick={() =>
                                    setDropDown({ isOpen: false, item })
                                  }
                                >
                                  {item}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {tab === 0 && (
          <GoodsStackInfo goodsListData={sortedGoodsList} tab={tab} />
        )}
        {tab !== 0 && (
          <GoodsCardInfo goodsListData={sortedGoodsList} tab={tab} />
        )}
        <div className="top_btn">
          <a>
            <img
              src={btnTop}
              alt=""
              onClick={() => scrollToRoot({ isSmooth: true })}
            />
          </a>
        </div>
      </div>
      <CategoryBottomSheet tab={tab} handleTab={handleTab} />
      <BottomNavigation />
    </div>
  );
};

export default Buy;
