import { useDispatch } from "react-redux";
import useCustomMutation from "@/hooks/queries/useCustomMutation";
import { showLoading, hideLoading } from "@/features/loading/loadingSlice";
import * as APIS from "@/services/apis";
import { routes } from "@/routes";
import { showToast } from "@/features/toast/toastSlice";
import { useNavigate } from "react-router-dom";
import usePendingCheck from "@/hooks/usePendingCheck";
import { IAccountInfo } from "@pages/myPage/hooks/useAccountInfo";
export interface IBankProps {
  bankCode: string;
  number: string;
  bankType: string;
}

interface IError {
  response?: {
    data?: {
      message: string;
    };
  };
}

const useBankAccountAssign = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pendingCheck = usePendingCheck();

  const pendingCompleteAccountCheck = (bankType: string) => {
    pendingCheck<IAccountInfo>({
      checkFn: () => APIS.getAccountInfo(bankType),
      successCondition: (data) => data.bankAccountStatus === "COMPLETED",
      processingCondition: (data) => data.bankAccountStatus === "REQUEST",
      processingMessage: "계좌 등록 중 입니다.",
      failureMessage: "계좌 등록에 실패하였습니다.",
      navigateOnSuccess: () => {
        dispatch(
          showToast({ message: "계좌가 등록되었습니다.", icon: "success" }),
        );
        navigate(-1);
      },
      navigateOnProcessing: () => navigate(routes.menu),
    });
  };

  const mutationFn = async (params: IBankProps): Promise<any> => {
    dispatch(showLoading());
    try {
      const response = await APIS.postBankAccount(params);
      dispatch(hideLoading());
      if (response.data.success) {
        pendingCompleteAccountCheck(params.bankType);
      } else {
        dispatch(showToast({ message: response.data.message, icon: "error" }));
      }
    } catch (error) {
      dispatch(hideLoading());
      const err = error as IError;
      if (err.response && err.response.data && err.response.data.message) {
        dispatch(
          showToast({ message: err.response.data.message, icon: "error" }),
        );
      }
      throw error;
    }
  };

  const mutation = useCustomMutation<IBankProps>(
    [routes.myAccountVerification],
    mutationFn,
  );

  return mutation;
};

export default useBankAccountAssign;
