import useCustomQuery from "@/hooks/queries/useCustomQuery";
import * as APIS from "@/services/apis";
import { showLoading, hideLoading } from "@/features/loading/loadingSlice";
import { useDispatch } from "react-redux";
import { routes } from "@/routes";

export interface ITradeIngResponse {
  requestAt: string;
  tradeType: string;
  requestId: number;
  status: string;
  statusDate: string | null;
  statusDateType: string | null;
  tradeNumber: string;
  assetType: string;
  productName: string;
  totalCount: number;
  requestKrw: number;
  requestPrice: number;
  requestGram: number;
  contractGram: number;
  userFee: number;
  imagePath: string;
  shopName: string;
  associateName: string | null;
  addTradeType: string | null; // 택배 여부
  brandName?: string;
  option?: string;
  assetTypeKor: string;
  tradeTypeKor: string;
  pureTradeKrw: number;
  tradeGram: number;
  tradeKrw: number;
  tradePoint: number;
  responseId: number;
  rawId: number;
}

const useTradeIngList = () => {
  const dispatch = useDispatch();

  const fetchDetails = async () => {
    dispatch(showLoading());
    try {
      const response = await APIS.getTradeHistoryIngList();
      dispatch(hideLoading());
      return response.data.data as ITradeIngResponse[];
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  const { data, error, isLoading, refetch } = useCustomQuery<
    ITradeIngResponse[]
  >([routes.tradeHistory, "ing"], fetchDetails);

  return { TradeIngList: data, isLoading, error, IngRefetch: refetch };
};
export default useTradeIngList;
