import React from "react";
import * as styles from "../style.css";
import { numberWithCommas, truncateToThreeDecimalPlaces } from "@/utils/utils";

import ico_info_24_blue from "@/assets/icons/ico_info_24px_bl.png";
import icoUp from "@assets/icons/ico_up.png";
import icoDown from "@assets/icons/ico_down.png";
import iconSame from "@assets/icons/icon_same.png";

interface Asset {
  averagePrice: number;
  earningsRate: number;
  totalPrice: number;
}

interface AssetItemProps {
  iconSrc: string;
  name: string;
  unit: string;
  holdingAmount: number;
  acceptableAmount: number;
  asset?: Asset;
  showProfit?: boolean; // 수익률 및 평단가를 보여줄지 여부
}

const AssetItem: React.FC<AssetItemProps> = ({
  iconSrc,
  name,
  unit,
  holdingAmount,
  acceptableAmount,
  asset = { averagePrice: 0, earningsRate: 0, totalPrice: 0 }, // 기본값 설정
  showProfit = true,
}) => {
  // showProfit이 true일 때만 asset 계산을 처리
  const averagePrice = showProfit ? asset.averagePrice : 0;
  const earningsRateNumber = showProfit ? asset.earningsRate : 0;
  const totalPrice = showProfit ? Math.floor(asset.totalPrice) : 0;

  // 수익률과 수익 계산 (양수로 표시하기 위해 절대값 처리)
  const absEarningsRate = Math.abs(earningsRateNumber || 0);
  const profit = showProfit
    ? Math.floor((totalPrice * earningsRateNumber) / 100)
    : 0;
  const absProfit = Math.abs(profit);

  return (
    <ul className={styles.commonList}>
      <img className={styles.iconImage} src={iconSrc} alt={`${name} 아이콘`} />
      <li className={styles.detailsContainer}>
        <div className={styles.detailItem}>
          <p className={styles.nameText}>{name}</p>
          <p>
            <span className={styles.priceText}>
              {numberWithCommas(holdingAmount)}
            </span>
            <span className={styles.unit}>{unit}</span>
          </p>
        </div>
        <div className={`${styles.detailItem} ${styles.holdingAsset}`}>
          <div>
            <img
              className={styles.infoIconImage}
              src={ico_info_24_blue}
              alt="info 아이콘"
            />
            <span className={styles.priceText}>거래대기</span>
          </div>
          <p>
            <span className={styles.priceText}>
              {numberWithCommas(
                truncateToThreeDecimalPlaces(holdingAmount - acceptableAmount),
              )}
            </span>
            {unit}
          </p>
        </div>
        {showProfit && (
          <>
            <div className={`${styles.detailItem} ${styles.extraInfo}`}>
              <div className={styles.extraLabel}>∙ 평단가</div>
              <div className={styles.profitContainer}>
                <span className={styles.extraPrice}>
                  {numberWithCommas(averagePrice)}
                </span>
                원
              </div>
            </div>
            <div className={`${styles.detailItem} ${styles.extraInfo} `}>
              <div className={styles.extraLabel}>∙ 수익률</div>
              <div
                className={`${styles.profitContainer} ${
                  earningsRateNumber > 0
                    ? styles.plus
                    : earningsRateNumber < 0
                    ? styles.minus
                    : styles.neutral
                }`}
              >
                <span>
                  (
                  {earningsRateNumber !== 0 ? (
                    <img
                      className={styles.polygonIcon}
                      src={earningsRateNumber > 0 ? icoUp : icoDown}
                      alt="수익률 업다운"
                    />
                  ) : (
                    <img
                      className={styles.sameIcon}
                      src={iconSame}
                      alt="수익률 0"
                    />
                  )}
                  <span className={styles.fontMedium}>
                    {truncateToThreeDecimalPlaces(absEarningsRate).toFixed(2)}
                  </span>
                  %)
                </span>
                <span className={styles.profitBreak}>
                  {earningsRateNumber !== 0
                    ? earningsRateNumber > 0
                      ? "+"
                      : "-"
                    : ""}
                  <span className={styles.fontMedium}>
                    {numberWithCommas(absProfit)}
                  </span>
                  원
                </span>
              </div>
            </div>
          </>
        )}
      </li>
    </ul>
  );
};

export default AssetItem;
