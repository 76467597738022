import React, { useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import { useSelector, useDispatch } from "react-redux";
import {
  hideToast,
  selectToast,
  type ToastIcon,
} from "@/features/toast/toastSlice";
import IconCheck from "@assets/images/img_check.png";
import IconError from "@assets/images/img_error.png";
import IconCaution from "@assets/images/img_caution02.png";
import IconInfo from "@assets/images/img_info02.png";

const Toast: React.FC = () => {
  const dispatch = useDispatch();
  const { open, message, second, icon } = useSelector(selectToast);

  useEffect(() => {
    if (open) {
      const tm = setTimeout(
        () => {
          dispatch(hideToast());
        },
        second ? second * 1000 : 3000,
      );
      return () => {
        clearTimeout(tm);
      };
    }
  }, [open, second, dispatch]);

  if (!open) {
    return null;
  }

  const getIcon = (icon: ToastIcon): string | undefined => {
    switch (icon) {
      case "success":
        return IconCheck;
      case "error":
        return IconError;
      case "caution":
        return IconCaution;
      case "info":
        return IconInfo;
      default:
        return undefined;
    }
  };

  const iconSrc = getIcon(icon);

  // 줄바꿈 처리
  const formattedMessage = message.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  return (
    <div className="snackbar_wrap">
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={second ? second * 1000 : 3000}
        onClose={() => dispatch(hideToast())}
      >
        <div className="snackbar_message">
          <p className="message-content">
            {iconSrc && <img src={iconSrc} alt="" className="snackbar_icon" />}
            {formattedMessage}
          </p>
        </div>
      </Snackbar>
    </div>
  );
};

export default Toast;
